<template>
  <router-view :style="environment == 'app' ? 'padding-top: 80px; padding-bottom: 100px;' : 'padding-top: 40px; padding-bottom: 100px;'"></router-view>
</template>

<script>
import router from '../../router'
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  data(){
    return {
      environment: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  mounted() {
    this.environment = process.env.NODE_ENV
    if (!this.user.data) {
      router.push('/login')
    }
    else {
      if(this.user.data.eltern){
        router.push('/eltern')
      }
      else if(this.user.data.verein){
        router.push('/verein')
      }
      this.user.show_admin = false
    }
  },
}
</script>
